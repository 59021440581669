<template>
  <router-link tag="a" :to="{ name: 'produit', params: { product_id: data.id, name: data.name.replaceAll(' ', '_') } }" class="produit tap" @click="$emit('produit_click')" :class="[ { reduc: data.reduc > 0 }, { load: load === false }]" :data-shadow="shadow">
    <div class="bg"></div>
    <div class="int">
        
      <div class="img loading_animation">

        <img_load class="img_load" @load="load = true" fit="contain" :id="data.img_key" :alt="data.name"></img_load>
        
<!--        <el-image class="img_load" :src="$static_patch + data.img" fit="contain" @load="load = true"></el-image>-->
  <!--      <img :src="lorem" alt="">-->
  <!--      <img src="https://dummyimage.com/500x500/eee/aaa" alt="">-->
        <div class="panier" v-if="panier">{{ panier.nb }}</div>
        <div class="reduc" v-if="data.reduc">-{{ data.reduc }}%</div>
  <!--      <div class="reduc2" v-if="data.reduc">-->
  <!--        <lottie-animation class="ico" :width="50" :height="50" path="lottie/discount.json" />        -->
  <!--      </div>-->
        
      </div>
      <div class="right">
        <div class="title">{{ data.name }}</div>
        <div class="line_price">
          <div class="prix">
            <lottie-animation v-if="data.reduc > 0" class="ico" :width="24" :height="24" path="lottie/discount.json" /> 
            {{ data.final_price | formatNumberdecimal }}<span class="euro">€</span> 
          </div>
          <div class="prix_reduc" v-if="data.reduc > 0">
            <span class="old">{{ data.price | formatNumberdecimal }}€</span>
          </div>
        </div>
  
  
      </div>
    </div>
  </router-link>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: "vignette",
  props: [ 'data', 'panier', 'shadow' ],
  data() {
    return {
      load: false
    }
  },
  components: {
    LottieAnimation
  },
  computed: {
    lorem() {
      return 'https://picsum.photos/seed/ ' + this.data.id + '/500/500'
    }
  }
}
</script>

<style scoped>
  .produit { position: relative; cursor: pointer; display: block; border: none }
  
  .produit .bg { position: absolute; top: 0; right: 0; left: 0; bottom: 0; background: #fff; border-radius: 16px; z-index: 1 }
  .produit .int { position: relative; z-index: 2 }
  /*.produits .produit:nth-child(odd) { mr }*/
  .produit .img { width: 100%; height: 0; padding-bottom: 100%; overflow: hidden; position: relative; }
  .produit .img .img_load { border-radius: 16px }
  .produit .img .img_load { width: 100%; }
  .produit .line_price { min-height: 40px; margin-top: 6px; display: flex; align-items: center; }
  
  
  .produit .line_price .prix { font-weight: bold; font-size: 20px; display: flex; align-items: center }
  .produit .line_price .prix .euro { font-size: 14px; padding-top: 4px; font-weight: 500; padding-left: 1px; }
  .produit.reduc .line_price .prix { color: #f44336; }
  .produit .line_price .prix .ico { margin-left: 0!important; margin-right: 4px!important; position: relative; top: -1px }
  .produit .line_price .prix_reduc { font-weight: 400; font-size: 16px; color: #C92C2C; margin-left: 8px; }
  .produit .line_price .prix_reduc .old { text-decoration: line-through; color: #898a8b }
  
  .produit .title { min-height: 40px; padding-bottom: 8px!important; font-size: 16px; line-height: 18px; overflow: hidden;
    text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 6; -webkit-box-orient: vertical; color: rgb(137,138,139)}
  .produit .panier { position: absolute; top: 0px; right: 0px; font-size: 12px; background: var(--gris); color: #333; height: 34px; width: 34px; display: flex; align-items: center;
    justify-content: center; border-radius: 0 0 0 16px;
    font-weight: 500; padding-top: 2px; font-size: 24px;
  }
  .produit .reduc { position: absolute; top: 16px; left: 0;  background: #f44336; color: #fff; height: 22px; display: flex; align-items: center;
    justify-content: center; border-radius: 1px; padding: 0 8px;
    font-family: "Prompt";  font-size: 18px;
  }
  .produit .reduc2 { position: absolute; top: 4px; left: 4px; }
  
  
  .produit .title { text-align: left; font-weight: 400; color: var(--black-sub) }
  .produit .line_price .prix { font-size: 20px }
  .produit .right { display: flex; flex-direction: column-reverse }

  .produit[data-shadow=true]:before { content: ''; position: absolute; left: 24%; right: 24%; top: 50px; bottom: 0; box-shadow: 0 5px 40px rgba(14,14,14,.2); border-radius: 0; }
  .produit[data-shadow=true] .img .img_load { border-radius: 6px 6px 0 0 }
  .produit[data-shadow=true] .title { text-align: center }
  .produit[data-shadow=true] .line_price { justify-content: center; }


  @media only screen and (min-width: 800px) {
    .produit { text-align: center; background: #fff; border-radius: 4px;  }
    .produit .img { width: 80%; padding-bottom: 80%; margin: 0% 10% 0 10%; border-radius: 2px }
    .produit .line_price { min-height: 40px; padding: 0 10%; margin-bottom: 10px; }
    .produit .title { padding: 0 10%; text-align: left }
    .produit.reduc .line_price .prix {  }
    .produit .line_price .prix { font-size: 20px;  }
    .produit .line_price .prix .ico { top: 0 }
    .produit .right { display: flex; flex-direction: column-reverse }
    
    
    /*LINE*/
    /*.produit { flex-direction: row; display: flex; }*/
    /*.produit .img { width: 14%; padding-bottom: 14%; margin: 20px }*/
    /*.produit .right { align-items: flex-start; justify-content: flex-start; flex-direction: column }*/
    /*.produit .right .title { text-align: left; padding: 0; }*/


    .produit:hover {  box-shadow: 5px 8px 12px rgba(141,149,158,0.11); }
    /*.produit:hover:after { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 1px solid var(--color); opacity: .5; border-right: 4px; }*/
    
    /*.produit.reduc:after { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 1px solid var(--color); opacity: .5; border-radius: 4px }*/
    /*.produit { display: flex; flex-direction: row-reverse; border: 1px solid #ddd }*/
    /*.produit .img { width: 40%; padding-bottom: 40%; }*/
    /*.produit .right { width: 60%; padding: 24px }*/
  }
</style>
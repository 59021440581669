<template>
  <div class="boutique" :class="{ load: load === false }">

<!--    <transition name="bounce" appear>-->
<!--    <router-link v-if="$panier_indicator > 0 && panier_load === true" class="panier_ico" tag="a" :to="{ name: 'panier', params: { boutique_id: $route.params.boutique_id } }">-->
<!--      <div class="ico">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><path d="M6 23.73l-3-2.122v-14.2l3 1.359v14.963zm2-14.855v15.125l13-1.954v-15.046l-13 1.875zm5.963-7.875c-2.097 0-3.958 2.005-3.962 4.266l-.001 1.683c0 .305.273.54.575.494.244-.037.425-.247.425-.494v-1.681c.003-1.71 1.416-3.268 2.963-3.268.537 0 1.016.195 1.384.564.422.423.654 1.035.653 1.727v1.747c0 .305.273.54.575.494.243-.037.423-.246.423-.492l.002-1.749c.002-1.904-1.32-3.291-3.037-3.291zm-6.39 5.995c.245-.037.427-.247.427-.495v-2.232c.002-1.71 1.416-3.268 2.963-3.268l.162.015c.366-.283.765-.513 1.188-.683-.405-.207-.858-.332-1.35-.332-2.096 0-3.958 2.005-3.962 4.266v2.235c0 .306.272.538.572.494z"/></svg>-->
<!--      </div>-->
<!--      <div class="indicator_nb">{{$panier_indicator}}</div>-->
<!--    </router-link>-->
    
    
    <router-link v-if="$panier_indicator > 0 && panier_load === true" class="panier_barre" tag="a" :to="{ name: 'panier', params: { boutique_id: $route.params.boutique_id } }">
      <button>
<!--        <div class="ico">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><path d="M6 23.73l-3-2.122v-14.2l3 1.359v14.963zm2-14.855v15.125l13-1.954v-15.046l-13 1.875zm5.963-7.875c-2.097 0-3.958 2.005-3.962 4.266l-.001 1.683c0 .305.273.54.575.494.244-.037.425-.247.425-.494v-1.681c.003-1.71 1.416-3.268 2.963-3.268.537 0 1.016.195 1.384.564.422.423.654 1.035.653 1.727v1.747c0 .305.273.54.575.494.243-.037.423-.246.423-.492l.002-1.749c.002-1.904-1.32-3.291-3.037-3.291zm-6.39 5.995c.245-.037.427-.247.427-.495v-2.232c.002-1.71 1.416-3.268 2.963-3.268l.162.015c.366-.283.765-.513 1.188-.683-.405-.207-.858-.332-1.35-.332-2.096 0-3.958 2.005-3.962 4.266v2.235c0 .306.272.538.572.494z"/></svg>-->
<!--        </div>-->
        <div class="indicator_nb">Voir mon panier <span v-if="panier_price">• {{ panier_price | formatNumberdecimal }}€</span></div>
      </button>
    </router-link>
<!--    </transition>-->


    
    <div class="boutique_pres">
      <div class="img_bg">

<!--            <el-upload v-if="$store.state.user.logged && $store.state.user.user.boutique == $route.params.boutique_id" -->
<!--                ref="upload"-->
<!--                :action="$api_ip + 'bo/boutique/image/cover'"-->
<!--                :before-upload="beforeCoverUpload"-->
<!--                :multiple="false"-->
<!--                :on-success="handleCoverSuccess"-->
<!--                :show-file-list="false"-->
<!--                class="button_cover animreverse"-->
<!--            >-->
<!--              <div class="txt">-->
<!--                <i class="el-icon-camera-solid"></i> Changer l'image de couverture-->
<!--              </div>-->
<!--            </el-upload>-->
        
        
        <div class="button_cover animreverse" v-if="$store.state.user.logged && $store.state.user.user.boutique == $route.params.boutique_id">
          <input @change="upload_img" type="file" id="file" accept="image/*">
          <!--                <img v-if="imageUrl" :src="imageUrl" alt="upload">-->
          <div class="txt">
            <i class="el-icon-camera-solid"></i> Changer l'image de couverture
          </div>
        </div>
        
        <div class="img loading_animation">
          
          <!--        <img :src="boutique.img" alt="">-->
          <el-image v-if="boutique && boutique.img_key" class="img_load" :src="$img_path +  boutique.img_key" fit="cover" @load="load = true"><div slot="error"></div></el-image>
  <!--        <img :src="lorem" alt="">-->
  <!--        <img src="https://dummyimage.com/1920x713/eee/aaa" alt="">-->
        </div>
      </div>
      <div class="cont">

        <div class="icones animreverse" v-if="boutique">
          <div class="ico" v-if="boutique.retrait_active">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M18.36 9L18.96 12H5.04L5.64 9H18.36M20 4H4V6H20V4M20 7H4L3 12V14H4V20H14V14H18V20H20V14H21V12L20 7M6 18V14H12V18H6Z" /></svg>
            <span>Retrait</span>
          </div>
          <div class="ico" v-if="boutique.livraison_active">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z" /></svg>
            <span>Livraison</span>
          </div>
<!--          <div class="ico">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" /></svg>-->
<!--            <span>Envoi</span>-->
<!--          </div>-->
        </div>
        
      <div class="pres" v-if="boutique">
        <div class="title anim">{{ boutique.name }}</div>
        <div class="tag alertstock online anim" v-if="!boutique.online && boutique.nextOpen && !boutique.nextOpen.nohours && !boutique.nextOpen.open && boutique.nextOpen.hours">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 24 24"><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
          Ouvre <template v-if="boutique.nextOpen.day">{{ boutique.nextOpen.day }}</template> à {{ boutique.nextOpen.hours[0] }}
        </div>
        <div class="tag alertstock online anim" v-else-if="!boutique.online">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 24 24"><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
          Momentanément indisponible
        </div>
<!--        <div class="tag enstock online anim" v-else-if="boutique.online">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 24 24"><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>-->
<!--          Ouvert-->
<!--        </div>-->

        
        
        <div class="desc anim">{{ boutique.description }}</div>

        <div class="infoLine red anim" v-if="boutique && !boutique.active && boutique.id === $store.state.user.user.boutique">Votre restaurant n'est pas en ligne, vous seul pouvez-voir le listing de vos produits.</div>

        
        <div class="adresse anim">
          <span class="addr capitalize"><span v-if="boutique.street_number !== '0'">{{ boutique.street_number }}</span> {{ boutique.route }}, {{ boutique.postal_code }} {{ boutique.locality }}</span>
          <span @click="boutique_infos_etat = true" class="plus_info">Plus d'informations</span>
        </div>
      </div>
    
    <div class="bar anim" v-if="boutique && produits &&  Object.keys(produits).length && (boutique.active || boutique.id === $store.state.user.user.boutique)">
      <input type="text" @click="search" id="search" class="search" ref="search" v-model="search_input" v-if="search_etat">
<!--      <el-input class="search_input" type="text" v-model="email" name="text"></el-input>-->
      <div class="search" @click="search" v-if="search_etat === false">
        <svg width="18" height="18" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 18.5787L16.2437 14.8223C17.4619 13.2995 18.2741 11.269 18.2741 9.13706C18.2741 4.06091 14.2132 0 9.13706 0C4.06091 0 0 4.06091 0 9.13706C0 14.2132 4.06091 18.2741 9.13706 18.2741C11.269 18.2741 13.2995 17.5635 14.8223 16.2437L18.5787 20L20 18.5787ZM2.03046 9.13706C2.03046 5.17766 5.17766 2.03046 9.13706 2.03046C13.0964 2.03046 16.2437 5.17766 16.2437 9.13706C16.2437 13.0964 13.0964 16.2437 9.13706 16.2437C5.17766 16.2437 2.03046 13.0964 2.03046 9.13706Z"></path>
        </svg>
        <span>Rechercher</span>
      </div>
    </div>
        

    <div class="filters v2 no_scrollbar anim" v-if="boutique && produits && Object.keys(produits).length && (boutique.active || boutique.id === $store.state.user.user.boutique)">
      <router-link :to="{ name: 'boutique_id', params: { boutique_id: boutique.id } }" class="filter" :class="{ active: categorie_active === 'all' }">Tous</router-link>
      <router-link :to="{ name: 'restoCategory', params: { boutique_id: boutique.id, category: n.replaceAll(' ', '_') } }" class="filter" :class="{ active: categorie_active === n }" v-for="(n, k) in boutique.categories" :key="k">{{ n }}</router-link>
    </div>


    
    <div class="produits" v-if="boutique && produits && Object.keys(produits).length && (boutique.active || boutique.id === $store.state.user.user.boutique)">
      <produit_vignette :shadow="true" class="produit anim" v-for="(n, k) in produits_active" :panier="panier[n.id]" :data="n" :key="k"></produit_vignette>
    </div>
        
        <div class="prochainement anim" v-else-if="boutique && !boutique.active">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,1L17.74,3.75L15,5L17.74,6.26L19,9L20.25,6.26L23,5L20.25,3.75M9,4L6.5,9.5L1,12L6.5,14.5L9,20L11.5,14.5L17,12L11.5,9.5M19,15L17.74,17.74L15,19L17.74,20.25L19,23L20.25,20.25L23,19L20.25,17.74" /></svg>
          Bientôt disponible
        </div>
        
        <div class="prochainement anim" style="text-align: center; font-weight: 400" v-else>
          Votre navigateur est trop ancien. Essayez Google Chrome ou mettez à jour.
        </div>
    
    <boutique_infos @close="boutique_infos_etat = false" :data="boutique" v-if="boutique_infos_etat === true"></boutique_infos>
    
    <footer_b :blank="true"></footer_b>
    </div>
    </div>
    
  </div>
</template>

<script>
import produit_vignette from "@/components/produit/produit_vignette";
import boutique_infos from "@/components/boutique/boutique_infos";
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import anime from 'animejs'
import Vuex from "vuex";
export default {
  data() {
    return {
      boutique_infos_etat: false,
      boutique_infos_data: {},
      
      categorie_active: 'all',
      
      search_etat: false,
      search_input: '',
      
      fuse: {},
      fuse_options: {},

      panier_load: false,
      
      load: false,
      anim_load: false,
      
      panier: {},
      
      produits: {},
      
      // boutique: {},
      // produits_get: [],
      produits_active: [],
      produits_last: [],
      
      panier_price: null
    }
  },
  computed: {
    ...Vuex.mapState({
      boutique: state => state.boutique.boutique,
      produits_get: state => state.boutique.produits,
    }),
  },
  methods: {
    init() {

      // const { set } = this.$meta().addApp('custom')
      // set({
      //   meta: [
      //       { vmid: 'ogimage', name: 'og:image', content: this.$img_path + this.boutique.img_key },
      //       { vmid: 'ogtitle', name: 'og:title', content: this.boutique.name + ' | Restaurant à ' + this.boutique.locality.toUpperCase() + ' | Livraison à domicile | MonResto.click' }
      //   ]
      // })
      
      
      // this.$http.get('/boutique/' + this.$route.params.boutique_id).then((response) => {
      //   this.boutique = response.data.boutique

        if(this.boutique && this.boutique.name && this.boutique.locality)
          document.title = this.boutique.name + ' | Restaurant à ' + this.boutique.locality.toUpperCase() + ' | Livraison à domicile | ' + document.title;



      // if(key)
      //   this.$route.params.boutique_id = this.boutique.id

      if(!this.$route.params.boutique_id)
        this.$route.params.boutique_id = this.boutique.id
      
      this.$boutique_id = this.boutique.id



      // this.init()

      let panier = localStorage.getItem("panier");
      panier = JSON.parse(panier)
      if(!panier) localStorage.setItem("panier", "{}")


      if(!panier[this.$route.params.boutique_id]) {
        panier[this.$route.params.boutique_id] = []
        localStorage.setItem('panier', JSON.stringify(panier));
      }
      else {
        if (!Array.isArray(panier[this.$route.params.boutique_id])) {
          // delete(panier[this.$route.params.boutique_id])
          panier[this.$route.params.boutique_id] = []
          localStorage.setItem('panier', JSON.stringify(panier));
        }
      }


      if(panier && panier[this.$route.params.boutique_id].length) {
        this.$http.post('/purchase/panier_calc', { panier: panier[this.$route.params.boutique_id] }).then((response) => {
          this.panier_price = response.data.total
        })
      }

      this.panier = panier[this.$route.params.boutique_id]
      this.$panier_indicator = 0

      for(let n in this.panier)
        this.$panier_indicator += this.panier[n].nb

      this.$forceUpdate()
        
      
        this.$loader = false

        this.produits = this.produits_get.filter(function(produit) {
          return produit.active === true;
        });
        // this.produits = response.data.produits
        this.produits_active = this.produits
        this.produits_last = this.produits


      if(this.$route.name === 'restoCategory' && this.$route.params.category) {
        this.change_categorie(this.$route.params.category.replaceAll('_', ' '), false)
      }
      else {
        this.change_categorie('all', false)
      }

        // this.produits_active =
      
        let anim_duration = 400
        let anim_img_duration = 400
        let stagger_duration = 20
        let stagger_reverse_duration = 30
      
        let y_animation = '30px'
      
        let anim_timeout = 300


        if(window.innerWidth < 800) {
          anim_timeout = 400
          anim_duration = 300
          anim_img_duration = 0
          // stagger_duration = 0
          stagger_reverse_duration = 0
  
          if(window.scrollY > 600) {
            anim_timeout = 0
          }
        }

        this.$load = true
        setTimeout(() => {
          if(window.scrollY > 600) {
            anim_duration = 350
            stagger_duration = 0
            stagger_reverse_duration = 0

            if(window.innerWidth > 800) {
              y_animation = '0px'
            }
            else {
              y_animation = 0
              anim_duration = 400
            }
          }

          let self = this
          if(self.anim_load === true)
            anim_duration = 0
          
          anime({
            targets: '.anim', translateY: [y_animation, 0], opacity: 1, easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', duration: anim_duration, delay: anime.stagger(stagger_duration)
          })
          anime({
            targets: '.img_load', easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', opacity: 1, duration: anim_img_duration
          })
          setTimeout(() => {
            anime({
              targets: '.animreverse', translateX: ['30px', 0], opacity: 1, easing: "cubicBezier(0.4, 0.0, 0.2, 1)", duration: anim_duration, delay: anime.stagger(stagger_reverse_duration)
            })
          }, 100)
          
          setTimeout(() => {
            self.panier_load = true
          }, anim_duration + 100)


          self.anim_load = true
          
        }, anim_timeout)

console.log('init')

      this.$root.$emit('triggerScroll')

      this.fuse_options = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "name"
        ]
      };

      this.fuse = new this.$fuze(this.produits_active, this.fuse_options);
      
      console.log(this.boutique)

      if(this.boutique.locality && this.boutique.insee && this.boutique.postal_code)
        this.$ville = { name: this.boutique.locality[0].toUpperCase() + this.boutique.locality.substring(1), code: this.boutique.insee, cp: this.boutique.postal_code, round: 0 }

      // })
    },
    change_categorie(categorie, anim = true) {
      this.categorie_active = categorie
      this.search_input = ''
      
      if(anim) {
        this.$nextTick(() => {
          var elems = document.querySelectorAll('.anim');
          var index = 0, length = elems.length;
          for ( ; index < length; index++) {
            elems[index].style.opacity = 1;
          }
        })
      }

      this.produits_last = this.produits_active
      
      if(categorie === 'all') {
        this.produits_active = this.produits
        this.produits_last = this.produits
        return
      }
      
      this.produits_active = this.produits.filter(function(produit) {
        return produit.categorie === categorie;
      });
      // setTimeout(() => {
    },
    search() {
      let self = this
      this.search_etat = true
      setTimeout(function() {
        self.$refs.search.focus()
      }, 10)
      

      this.fuse = new this.$fuze(this.produits_active, this.fuse_options)
    },
    // handleCoverSuccess(res) {
    //   this.$http.post('/bo/boutique/edit', {
    //     id: this.$store.state.user.user.boutique,
    //     key: 'img',
    //     value: res
    //   }).then(() => {
    //     this.$store.dispatch("boutique/sync_force", this.$route.params.boutique_id);
    //   }).catch(e => console.log(e))
    // },
    // beforeCoverUpload(file) {
    //   const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    //   const isLt2M = file.size / 1024 / 1024 < 1200;
    //
    //   if (!isJPG) this.$message.error('Avatar picture must be JPG format!');
    //   if (!isLt2M) this.$message.error('Avatar picture size can not exceed 12MB!');
    //
    //   return isJPG && isLt2M;
    // },
    upload_img(e) {
      this.$upload_img(e, 1080, 'boutique').then((d) => {
        this.$http.post('/bo/boutique/edit', {
          key: 'img_key', value: d.key
        }).then(() => {
          this.$store.dispatch("boutique/sync_force", this.$route.params.boutique_id);
        })
      })
    },
    sync(key=false) {
      if(key)
        this.$store.dispatch("boutique/syncKey", this.$route.params.boutique_key);
      else
        this.$store.dispatch("boutique/sync", this.$route.params.boutique_id);
        
      
    }
  },
  name: "boutique",
  components: {
    produit_vignette,
    boutique_infos,
    // LottieAnimation
  },
  watch: {
    $route() {
      this.init()
    },
    search_input() {
      if (this.search_input.trim() === '')
        this.produits_active = this.produits_last
      else {
        this.produits_active = []
        let f = this.fuse.search(this.search_input.trim())
        for(let n in f) 
          this.produits_active.push(f[n].item)
      }
      
      this.$nextTick(() => {
        var elems = document.querySelectorAll('.anim');
        var index = 0, length = elems.length;
        for ( ; index < length; index++) {
          elems[index].style.opacity = 1;
        }
      })
    },
    boutique() {
      this.init()
    }
  },
  created() {
    
    this.$load = false
    this.$loader = true
    
    // let self = this

    if(this.$route.name === 'boutique_key') {
      // this.$http.get('/boutique/bykey/' + this.$route.params.boutique_key).then((data) => {
      //   document.title = data.data.name + ' | Restaurant à ' + data.data.locality.toUpperCase() + ' | Livraison à domicile | MonResto.click';
      //   this.$route.params.boutique_id = data.data.boutique_id
      //   this.sync()
      //   if (this.produits_get && this.boutique && parseInt(this.boutique.id) === parseInt(this.$route.params.boutique_id)) {
      //     this.init()
      //   }
      // }).catch(() => {
      //   this.$router.push({ name: 'Home' })
      // }) 
      this.sync(true)
      if (this.produits_get && this.boutique && this.boutique.key === this.$route.params.boutique_key) {
        this.init()
      }
      
    }
    else {      
      if(this.$route.params.name && this.$route.params.locality)
        document.title = this.$route.params.name.replaceAll('_', ' ') + ' | Restaurant à ' + this.$route.params.locality.replaceAll('_', ' ').toUpperCase() + ' | Livraison à domicile | MonResto.click';
      else if (this.$route.params.name)
        document.title = this.$route.params.name.replaceAll('_', ' ') + ' | Livraison à domicile | MonResto.click';
      
      
      this.sync()
      if (this.produits_get && this.boutique && parseInt(this.boutique.id) === parseInt(this.$route.params.boutique_id)) {
        this.init()
      }
    }


    
  }
}
</script>

<style>
  /*.anim { opacity: 1; }*/
</style>
<style scoped>

  .boutique { background: #f4f4f4 }
  
  .panier_ico { animation: bounce-in .5s 0s; animation-fill-mode: both; transform: translateX(500px); border: none }
  
  .panier_barre { padding: 20px 24px; background: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1));  z-index: 15;
    position: fixed; bottom: 0; right: 0; left: 0; animation: panier_barre_show .3s 0s cubic-bezier(0.4, 0.0, 0.2, 1); animation-fill-mode: both;
    box-shadow: 0 -4px 50px rgb(204 204 204 / 50%); border-radius: 30px 30px 0 0; background: #fff; border: none;
  }
  @keyframes panier_barre_show {
    0% { transform: translateY(100px); opacity: 0; }
    100% { transform: translateY(0px); opacity: 1; }
  }
  
  .panier_barre button { width: 100%; display: flex; background: var(--vert-button); align-items: center; justify-content: center; border-radius: 24px; font-size: 16px; }
  .panier_barre svg { fill: #fff; width: 24px; margin-right: 8px; }
  

  .boutique_pres { position: relative; background-color: #fff; }
  .boutique_pres .img_bg { background: #fff; position: absolute; top: 0; right: 0; left: 0; overflow: hidden; }
  .boutique_pres .img_load { opacity: 0; }
  .boutique_pres .img_bg .button_cover { position: absolute; top: 10px; right: 10px; z-index: 2; font-size: 14px; background: rgba(50,50,50,.8); height: 36px; padding: 0 14px;
    border-radius: 6px; color: #fff; cursor: pointer;display: flex; align-items: center; justify-content: center;
  }
  .boutique_pres .img_bg .button_cover input { position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; z-index: 2; cursor: pointer }
  .boutique_pres .img_bg .button_cover .txt { display: flex; align-items: center; justify-content: center;  }
  .boutique_pres .img_bg .button_cover i { margin-right: 6px; font-size: 18px; position: relative; top: -1px }
  .boutique_pres .cont .icones { position: absolute; right: 0; top: -44px; background: #ef8934; display: flex; border-radius: 10px 0 0 10px; }
  .boutique_pres .cont .icones .ico { width: 60px; height: 60px; display: flex; align-items: center; color: #fff; fill: #fff; justify-content: center; flex-direction: column }
  .boutique_pres .cont .icones .ico span { font-size: 10px; padding-top: 2px; }

  .boutique_pres .img { width: 100%; height: 0; padding-bottom: 69%; background: #e9e9e9; overflow: hidden; position: relative;} /* 36.64%*/
  /*.boutique_pres .img:after { position: absolute;  }*/
  .boutique_pres .img_load { width: 100%; position: absolute; top: 0; bottom: 0; border-radius: 0 0 64px 0 }
  /*.boutique_pres .img img { transform: scale(1.5) }*/
  .boutique_pres .cont { position: relative; top: 55vw; }
  .boutique_pres .pres { border-radius: 54px 0 0 0; background: #fff; padding: 34px 24px 0 24px; z-index: 2;  }
  .boutique_pres .title { display: flex; font-size: 26px; font-weight: bold; align-items: center; }
  .boutique_pres .online { margin-top: 8px; }
  .boutique_pres .desc { font-size: 15px; white-space: pre-line; margin-top: 14px; font-weight: 400; color: var(--black-sub); margin-bottom: 0px; }
  .boutique_pres .adresse:before { content: ''; width: 40px; height: 2px; background: var(--gris); position: absolute; top: 0px }
  .boutique_pres .adresse { height: 50px; font-weight: 400; font-size: 16px; display: flex; align-items: center; position: relative; margin-top: 16px; }
  .boutique_pres .adresse .addr { width: 50%; font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; color: var(--black-sub) }
  .boutique_pres .adresse .plus_info { position: absolute; right: 0; top: 0px; bottom: 0; display: flex; align-items: center;
    cursor: pointer; font-weight: 500;
  }

  .prochainement { display: flex; padding-right: 24px; padding-left: 24px; flex-wrap: wrap; background: #fff; min-height: 36vh;
    align-items: center; justify-content: center; font-weight: 600; color: var(--black-sub); fill: var(--black-sub)
  }
  .prochainement svg { margin-right: 12px; }
  
  .produits { display: flex; padding-right: 24px; padding-bottom: 16px; padding-left: 24px; flex-wrap: wrap; background: #fff; justify-content: space-between; min-height: 34vh; }

  .produits .produit {  width: calc(50% - 16px); margin: 16px 0 0 0; }
  
  .bar { height: 50px; background: #fff; display: flex; align-items: center; justify-content: flex-start; padding: 0 24px;
    font-size: 16px;
  }
  .bar .search { cursor: pointer; background: var(--input); color: var(--black-sub); height: 50px; padding: 0 24px; width: 100%; border-radius: 10px; display: flex; align-items: center;
    border: none; outline: none; 
  }
  /*.bar input.search*/
  .bar span { position: relative; bottom: -2px; }
  .bar svg { fill: var(--black-sub); margin-right: 10px; }

  .filters { padding: 16px var(--padding) 4px var(--padding); background: #fff; }
  .filters a { text-decoration: none; border: none }
  
  .infoLine { margin-bottom: 16px; position: relative; top: -4px }

  /*.search_input { background: var(--input); padding: 0 20px; border: none; width: 100%; height: 100%; outline: none; font-weight: 500; font-size: 22px}*/
  
  .panier_ico { width: 60px; height: 60px; border-radius: 50%; position: fixed; bottom: 24px; right: 24px; display: flex; align-items: center; justify-content: center;
    /*background: var(--vert-button);*/ color: #fff; fill: #fff; box-shadow: 3px 3px 6px 1px rgba(200,200,200,.4); cursor: pointer; z-index: 100;
    background: transparent linear-gradient(90deg,#f23f3f,#fb7f2a) 0 0 no-repeat padding-box;
  }
  .panier_ico .indicator_nb { position: absolute; top: 54.5%; left: 54.3%; transform: perspective(80px) translate(-50%, -50%) rotateY(-15deg) rotateZ(0deg); color: #f44e3a;
    font-family: "Product Sans"; font-weight: bold; font-size: 16px; 
  }
  .panier_ico .ico svg {  }
  
  /*.panier_ico { position: fixed; bottom: 20px; right: 20px; display: flex; align-items: center; background: #fff; border: 1px solid #eee; height: 50px; border-radius: 4px;*/
  /*  box-shadow: 1px 1px 4px rgb(222 194 170), 3px 3px 6px #c7c7c7; padding: 1px; cursor: pointer;*/
  /*}*/
  /*.panier_ico .ico { margin: 0px; width: 50px; }*/
  /*.panier_ico .indicator_nb { background-color: #ffe6d1; font-family: "Prompt"; font-weight: 500; color: #c75e03; height: 100%; display: flex; align-items: center; justify-content: center; padding: 0 26px }*/
  
  /*.panier_ico .indicator { position: absolute; top: -8%; right: -7%; background: #ff5f5f; border-radius: 100%; width: 12px; height: 12px; }*/
  
  
  
  @media only screen and (min-width: 800px) {
    
    .panier_barre { left: inherit; bottom: 0px; border-top-right-radius: 0px; border-top-left-radius: 6px; }
    .panier_barre button { border-radius: 2px }
    .boutique_pres { }
    .boutique_pres .img { padding-bottom: 25.5%; margin-bottom: 0; border-radius: 0; background: #fff; }
    .boutique_pres .img_bg svg { position: absolute; top: 0; bottom: 0; height: 100%; left: -80px; z-index: 2; transform: rotate(140deg) }
    .boutique_pres .img_bg { position: relative; }
    .boutique_pres .img_load { position: absolute; top: -25%; left: 0; right: 0; }
    .boutique_pres .pres { position: relative; top: 0; left: 0; max-width: 680px; border-radius: 0; background: #fff; transform: translateY(-50%); padding: 24px 24px 0px var(--padding); min-width: 600px; 
      border-radius: 0 36px 0 0; transform: none; margin-top: -181px; min-height: 181px; background: linear-gradient(-178deg, rgba(255,255,255,0.89), rgba(255,255,255,1) 25%);
    }
    .boutique_pres .pres .title { font-size: 38px; font-weight: 900; }
    .boutique_pres .adresse { border: none }
    .boutique_pres .adresse .addr { width: 75% }
    .boutique_pres .cont .icones { top: 27.1vw; right: var(--padding); z-index: 5; background: #fff; }
    .boutique_pres .cont .icones .ico { width: 80px; height: 80px; }
    .boutique_pres .cont .icones svg { width: 40px; height: 40px; fill: var(--black-main); }
    .boutique_pres .cont .icones .ico span { font-size: 16px; color: var(--black-main) }

    .filters { padding-top: 0; }
    
    .boutique_pres .cont { position: static; top: 0 }
    .produits { padding: 8px var(--padding); justify-content: flex-start; padding-bottom: 30px; }
    /*.produits .produit {  width: 100% }*/
    .produits .produit {  width: calc((100% / 5) - 0px);  }
    .produits .produit:nth-child(5n +1) { margin-left: 0; }
    
    .bar { background: #fff; padding: 16px var(--padding) 6px var(--padding); height: 90px; }
    .bar .search { width: 350px; }
    /*.bar svg { fill: #fff; }*/

    .search_input {  }
    


  }


  
</style>
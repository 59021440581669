<template>
  <div class="boutique_infos">
    <svg @click="$emit('close')" class="close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>

    <div class="carte">
      <gmap-map
          :center="center"
          :options="{
           zoomControl: false,
           mapTypeControl: false,
           draggable: true,
           scaleControl: true,
           streetViewControl: false,
           rotateControl: false,
           fullscreenControl: false,
           disableDefaultUi: false
         }"
          :zoom="18"
          class="map"
          style="width:100%;  height: 400px;"
      >
        <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            @click="center=m.position"
        ></gmap-marker>
      </gmap-map>
      
    </div>


    <div class="info_line">
      <div class="right">
        Adresse <span><i v-if="data.street_number !== '0'">{{ data.street_number }}</i> {{ data.route }}, {{ data.postal_code }} {{ data.locality[0].toUpperCase() + data.locality.substring(1) }}</span>
      </div>
    </div>
    <div class="info_line">
      <div class="right">
        Téléphone <span><a :href="'tel:' + data.national_phone_number">{{ data.national_phone_number }}</a></span>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "boutique_infos",
  props: [ 'data' ],
  data() {
    return {
      center: {lat: 48.866, lng: 2.261}, markers: [], places: [], currentPlace: null,
    }
  },
  created() {
    // if (this.currentPlace) {
      const marker = {
        lat: this.data.geo.lat, lng: this.data.geo.lng
      };
      this.markers.push({position: marker});
      this.center = marker;
    // }
  }
}
</script>

<style scoped>

  .boutique_infos { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 70 }
  .carte { height: 400px; background: #eee; display: flex; align-items: center; justify-content: center; }
  
  .close { position: absolute; left: 20px; top: 20px; cursor: pointer; z-index: 2 }



</style>